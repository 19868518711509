<template>
  <NuxtLink
    :class="[
      computedClass,
      underline && 'before:scale-x-100'
    ]"
    v-bind="$attrs"
    class="relative before:content-[''] before:absolute before:block before:w-full before:h-[2px]
        before:bottom-0 before:left-0
        before:hover:scale-x-100 before:scale-x-0 before:origin-bottom-left
        before:transition before:ease-in-out before:duration-300 cursor-pointer before:hover:delay-0 before:delay-150"
  >
    <slot />
  </NuxtLink>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  variant: {
    type: String,
    default: 'primary',
  },
  underline: {
    type: Boolean,
    default: false,
  },
});

const computedClass = computed(() => {
  const classMap = {
    primary: 'before:bg-brand-primary text-brand-black',
    white: 'before:bg-brand-white text-brand-white',
  };

  return classMap[props.variant];
});
</script>
